import {
  getCookie as avGetCookie,
  setCookie as avSetCookie,
} from '@avito-ma/utils'
import { cookieBaseDomain } from '../config/constants'

const avitoCookieDomainString = `domain=${cookieBaseDomain};`
let cookieDomainString

// In develoment we can't read another origin's cookies
if (typeof window === 'object' && document.domain === 'localhost') {
  cookieDomainString = ''
} else {
  cookieDomainString = avitoCookieDomainString
}

export function setCookie(name, value) {
  return avSetCookie(name, value, cookieDomainString)
}

export const getCookie = (name) => {
  return avGetCookie(name)
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${cookieDomainString} path=/;`
}