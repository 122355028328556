import React from 'react'
import './index.scss'

export const CustomCheckBox = ({
  name,
  defaultValue,
  label,
  required,
  onChange,
  checked
}) => (
  <>
    <input
      type='checkbox'
      name={name}
      defaultChecked={defaultValue}
      required={required}
      className='termsInput'
      onChange={onChange}
      checked={checked}
    />
    {label}
  </>
)