import React, { useState, useRef } from 'react'

import Select from 'react-select'

function RequiredSelect(props) {
  const [state, setState] = useState({
    value: props.value,
  })
  const selectRef = useRef(null)
  const onFocus = () => {
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }
  const onChangeFunc = value => {
    props.onChange(value)
    setState({ value })
  }
  const getValue = () => {
    if (props.value !== undefined && props.value !== null) {
      return props.value
    }
    return state.value || ''
  }

  return (
    <>
      <Select {...props} onChange={onChangeFunc} ref={selectRef} />
      <input
        tabIndex={-1}
        autoComplete='off'
        style={{
          opacity: 0,
          width: '100%',
          height: 0,
          position: 'absolute',
        }}
        value={getValue()}
        onChange={() => {}}
        onFocus={onFocus}
        required
      />
    </>
  )
}

export default RequiredSelect
