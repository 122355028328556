import React from 'react'
import { Icon } from '@avito-ma/mosaic'

import * as S from './Footer.styled'

const baseUrl = 'http://www.avito.ma'

const links = [
  {
    label: 'Aide',
    action: `${baseUrl}/fr/aide.htm`,
  },
  {
    label: 'Achat sécurisé',
    action: `${baseUrl}/s%C3%A9curit%C3%A9/index.htm`,
  },
  {
    label: 'Règlement',
    action: `${baseUrl}/r%C3%A8glement.htm`,
  },
  {
    label: 'Publicité',
    action: 'https://www.avitomedia.com/fr/',
  },
  {
    label: 'Qui sommes-nous ?',
    action: `${baseUrl}/qui_sommes-nous.htm`,
  },
  {
    label: 'Avito S.A.R.L',
    action: `${baseUrl}/fr/copyright.htm`,
  },
]

// Social Media Object
const socialMedia = [
  {
    icon: 'Facebook',
    color: '#3b5998',
    action: 'https://www.facebook.com/Avito.ma',
  },
  {
    icon: 'Twitter',
    color: '#1da1f2',
    action: 'https://www.twitter.com/Avitoma',
  },
  {
    icon: 'InstagramLine',
    color: '#e1306c',
    action: 'https://www.instagram.com/avito.ma',
  },
  {
    icon: 'Youtube',
    color: '#ff0000',
    action: 'https://www.youtube.com/channel/UCtslQB0IQ98Hfd-V3rNhzjA',
  },
  {
    icon: 'Linkedin',
    color: '#0077b5',
    action: 'https://www.linkedin.com/company/avito-ma',
  },
]

// Stores object

const store = [
  {
    badge: 'app-store-badge',
    alt: 'av.footer.download.playstore',
    action: 'https://apps.apple.com/fr/app/avito-ma/id740544172',
  },
  {
    badge: 'play-store.badge',
    alt: 'av.footer.download.appstore',
    action: 'https://play.google.com/store/apps/details?id=se.scmv.morocco',
  },
]

export const Footer = ({ withInternalLinks }) => {
  return (
    <S.Footer>
      <S.FooterPrimary>
        <S.Container>
          <S.PagesList>
            {links.map(({ label, action }, index) => (
              <S.PageItem key={`${label}-${index}`}>
                <S.PageLink to={action}>{label}</S.PageLink>
              </S.PageItem>
            ))}
          </S.PagesList>
        </S.Container>
      </S.FooterPrimary>

      {withInternalLinks && (
        <S.FooterSecondary>
          <S.Container></S.Container>
        </S.FooterSecondary>
      )}

      <S.FooterTertiary>
        <S.Container>
          <S.Stores>
            <S.FooterTertiaryText>
              Téléchargez l'application gratuitement
            </S.FooterTertiaryText>
            <S.StoresList>
              {store.map(({ badge, alt, action }, index) => (
                <S.StoreItem key={`${badge}-${index}`}>
                  <S.StoreLink to={action} target="_blank">
                    <S.StoreBadge alt={alt} src={`../${badge}.svg`} />
                  </S.StoreLink>
                </S.StoreItem>
              ))}
            </S.StoresList>
          </S.Stores>

          <S.SocialMedia>
            <S.FooterTertiaryText>Suivez nous sur</S.FooterTertiaryText>
            <S.SocialMediaList>
              {socialMedia.map(({ icon, label, color, action }, index) => (
                <S.SocialMediaItem key={`${label}-${index}`}>
                  <S.SocialMediaLink to={action} color={color} target="_blank">
                    <Icon color="white" name={icon} size="sm" />
                  </S.SocialMediaLink>
                </S.SocialMediaItem>
              ))}
            </S.SocialMediaList>
          </S.SocialMedia>
        </S.Container>
      </S.FooterTertiary>
    </S.Footer>
  )
}
