import React, { Component } from 'react'
import axios from 'axios'
import Select from 'react-select'
import EMail from 'react-email-autocomplete'
import { Layout } from '../components/common'
import { Footer } from '../components/Footer'
import { HeaderUi } from '../components/header/Header'

import Ytube from 'react-youtube'

import Phone from '../assets/icons/phone.svg'
import Slider from '../components/Slider'
import Swiper from '../components/Swiper'
import Money from '../assets/icons/money.svg'
import Email from '../assets/icons/email.svg'
import Size from '../assets/icons/size.svg'
import Location from '../assets/icons/location.svg'
import Key from '../assets/icons/key.svg'
import Facebook from '../assets/icons/facebook.svg'
import Instagram from '../assets/icons/instagram.svg'
import Youtube from '../assets/icons/youtube.svg'
import Website from '../assets/icons/website.svg'
import Arrow from '../assets/icons/arrow.svg'
import SweetAlert from 'sweetalert2-react'
import { CustomCheckBox as CheckBox } from '../components/checkbox'
import { CGU_URL, CITIES } from '../config/constants'
import { setCookie, getCookie } from '../utils/cookie'
import RequiredSelect from '../components/common/required-select'

// const LEADSFORCE_URL =
//   'https://api.leadsforce.ma/ws/leads/store?reseller=2bd38a3852c6948146b5a31f3673841d'

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  )
}

// Enable Localstorage for webview browser
function isSupported(storage) {
  try {
    const key = 'dd969e893e201b9ef70ded634fd48c8c'
    storage.setItem(key, key)
    storage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

const TermsLabel = () => (
  <span>
    J'accepte les
    <a href={CGU_URL} target='_blank' rel='noreferrer'>
      {` conditions générales d'utilisation`}
    </a>
  </span>
)

const StayInformedLabel = () => (
  <span>Je veux être informé(e) des nouveautés</span>
)

function leadsforceFetch(url, args) {
  return new Promise((resolve, reject) => {
    fetch(url, args)
      .then(response => {
        response.json().then(result => {
          if (result?.code !== 200 || result?.req?.[0] === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
      .catch(error => {
        reject(error)
      })
  })
}

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: this.props.pageContext.meta.name || '',
      },
      campaign: {},
      medium: {},
      source: {},
      success: false,
      isPhoneVisible: false,
      value: '',
      isArabic: false,
      selectedOption: {},
      selectedOption2: {},
      selectedCountryCode: { label: '+212', value: '+212' },
      itemsDropdown: {},
      opts2: [],
      clientSheet: '',
      title: '',
      termsCheck: false,
      stayInformedCheck: true,
    }
  }

  setPrefillDataCookie = data => {
    const formDataCookieValue = getCookie('prefill')
    const stayInformed = data?.stayInformed === true ? data?.stayInformed : ''
    const prefilledChecks = `${data?.terms},${stayInformed}`

    formDataCookieValue !== prefilledChecks &&
      setCookie('prefill', prefilledChecks)
  }

  getPrefillDataCookie = () => {
    const formDataCookieValue = getCookie('prefill')
    const canPrefillForm = Boolean(formDataCookieValue)

    if (canPrefillForm) {
      const cookieValuesArray = formDataCookieValue.split(',')
      this.setState({
        termsCheck: Boolean(cookieValuesArray[0]),
        stayInformedCheck: Boolean(cookieValuesArray[1]),
      })
    }
  }

  componentDidMount() {
    this.getPrefillDataCookie()
    const { campaign, medium, source } = this.state
    const search = this.props.location.search
    const params = new URLSearchParams(search)
    const mediumUTM = params.get('utm_medium')
    const campaignUTM = params.get('utm_campaign')
    const sourceUTM = params.get('utm_source')
    if (campaign || medium || source) {
      this.setState({
        campaign: campaignUTM,
        medium: mediumUTM,
        source: sourceUTM,
      })
    }

    isSupported(window.localStorage)

    // Test if the utag func eixts
    if (typeof window.utag !== 'undefined') {
      const isMobile = window.innerWidth < 850 ? 'mobile' : 'desktop'
      window.utag.view({
        tealium_event: 'visited_celerity_page',
        utm_campaign: campaignUTM,
        utm_medium: mediumUTM,
        utm_source: sourceUTM,
        device: isMobile,
        platform: 'web-celerity',
        app_name: 'celerity',
        project_id: this.state.data.id,
      })
    } else {
      console.log('not detected')
    }
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    )
  }

  handleChange2 = selectedOption2 => {
    this.setState({ selectedOption2 }, () =>
      console.log(`Option selected:`, this.state.selectedOption2)
    )
  }

  handleCountryCodeChange = selectedValue => {
    this.setState({ selectedCountryCode: selectedValue })
  }

  handleCityChange = city => {
    this.setState({ data: { ...this.state.data, city: city?.value } })
  }

  handleCheckBoxChange = e => {
    const checkBoxValue = e.target.checked
    const checkBoxName = e.target.name
    checkBoxName === 'terms'
      ? this.setState({ termsCheck: checkBoxValue })
      : this.setState({ stayInformedCheck: checkBoxValue })
  }

  _onReady(event) {
    console.log('player Activated')
  }

  allowNumeric(e) {
    if (e.target.value.length === 12) {
      e.preventDefault()
      return
    }
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  handleInput = e => {
    let name = e.target.name
    let val = e.target.value
    let data = this.state.data

    data[name] = val
    this.setState({ data })
  }

  getBackgroundStyle = link => {
    return {
      backgroundImage: `url("${link}")`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
    }
  }

  formatLinkAbsolute = link => {
    return link.includes('http') ? link : '//' + link
  }

  showPhone = () => {
    this.setState({ isPhoneVisible: true })
  }

  // Detect whether is the uncoding is on RTL or LTR
  isArabic = () => {
    const { data } = this.state
    var arabic = /[\u0600-\u06FF]/
    var textData = data.modules.imageText.text
    arabic.test(textData)
    this.setState({ isArabic: true })
  }

  trackPixelEvent = () => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'Purchase', { currency: 'MAD', value: 0 })
      }
    }
  }

  submit = e => {
    e.preventDefault()
    this.setState({ submitting: true })

    const { leadsforceCampaign } = this.props?.pageContext?.meta
    const pageUrl = this.props?.location?.href
    let date = new Date()
    date =
      date.getDay() +
      '/' +
      date.getMonth() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes()
    let data = { ...this.state.data }
    let campaign = this.state.campaign
    let medium = this.state.medium
    let source = this.state.source
    data.date = date
    let device = window.innerWidth < 850 ? 'mobile' : 'desktop'
    const countryCode = this.state.selectedCountryCode?.value

    data.name = data.name.replace(/[^\w\s\u0621-\u064A]/g, '')
    data.phone = `${countryCode}${data.phone.replace(/[^\w\s]/g, '')}`
    data.campaign = campaign
    data.medium = medium
    data.source = source
    data.device = device
    data.selectedOption = this.state.selectedOption.label || ''
    data.selectedOption2 = this.state.selectedOption2.label || ''

    let checkBoxesData = {
      terms: this.state.termsCheck,
      stayInformed: this.state.stayInformedCheck,
    }

    this.setPrefillDataCookie(checkBoxesData)

    const storeLeadPromise = Promise.any([
      leadsforceFetch(
        'https://us-central1-landing-gen-dev-25ef8.cloudfunctions.net/sendLead',
        {
          method: 'POST',
          body: JSON.stringify({
            leads: [
              {
                platform: 'avito',
                campaign: leadsforceCampaign,
                saved: formatDate(new Date()),
                link_referrer: `${pageUrl}?utm_source=celerity`,
                source: 'celerity',
                user_agent: '',
                tags: {
                  name: data?.name,
                  phone: data?.phone,
                  email: data?.email,
                  city: data?.city,
                  option_1: data?.selectedOption,
                  option_2: data?.selectedOption2,
                },
              },
            ],
          }),
        }
      ),
      axios.get('https://cplead.avito.ma/CPL/celerity/add_lead.php', {
        params: data,
      }),
    ])

    storeLeadPromise
      .then(() => {
        this.setState({ submitting: false, success: true })
        this.trackPixelEvent()
      })
      .catch(e => {
        this.setState({ submitting: false, success: false })
        console.log(e)
      })
  }

  render() {
    let data = this.props.pageContext
    let { color = '' } = data.meta || {}

    const isColor = color

    const customMail = [
      'gmail.com',
      'yahoo.com',
      'yahoo.fr',
      'outlook.com',
      'outlook.fr',
      'hotmail.com',
      'hotmail.fr',
    ]

    // Fix for youtube
    const vidOptions = {
      playerVars: {
        autoplay: 1,
        mute: 1,
      },
    }

    let {
      title: maintitle = '',
      subtitle: mainsubtitle = '',
      type = '',
      url = '',
      logo = '',
      button = 'Envoyer',
      background = '',
      redirect = '',
      itemInput = [],
      itemInputSecond = [],
      iteminputName = '',
      iteminputName2 = '',
      textInput = '',
    } = data.meta || {}

    let { price = '', location: relocation = '', surface = '', delivery = '' } =
      data.re || {}
    let {
      phone = '',
      email = '',
      web = '',
      youtube = '',
      facebook = '',
      instagram = '',
    } = data.social || {}

    let {
      video = '',
      imagetext = '',
      gallery = '',
      location = '',
      download = '',
    } = data.modules || {}

    color = color.hex

    return (
      <Layout>
        <HeaderUi />
        <div
          className='mainContainer'
          style={this.getBackgroundStyle(background)}
        >
          <div className='mainContent'>
            <div className='navbar'>
              <div className='logo'>
                {logo && <img src={logo} alt='' id='avito-logo' />}
              </div>
              {phone && (
                <div className='phone' style={{ color: color }}>
                  <Phone id='navbar-phone' style={{ fill: color }} />
                  {!this.state.isPhoneVisible ? (
                    <span id='showNum' onClick={this.showPhone}>
                      Afficher Le Numéro
                    </span>
                  ) : (
                    <a href={'tel:' + phone}>{phone}</a>
                  )}
                </div>
              )}
            </div>
            <div className='hero'>
              <div className='media'>
                {type === 'crea' && (
                  <a target='_blank' rel='noopener noreferrer' href={redirect}>
                    <img
                      src={url}
                      style={{ cursor: 'pointer' }}
                      alt=''
                      id='hero-image'
                    />
                  </a>
                )}
                {type === 'carousel' && (
                  <div className='hero-carousel'>
                    <Swiper images={url} />
                  </div>
                )}
                {type === 'ecatalog' && (
                  <div className='hero-carousel catalog-container'>
                    <iframe
                      src={
                        'https://docs.google.com/viewer?url=' +
                        url +
                        '&embedded=true'
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '600px',
                      }}
                    />
                  </div>
                )}
                {type === 'video' && (
                  <div className='video-container'>
                    <Ytube
                      // TODO it should be video id instead of url
                      videoId={video.url ?? url}
                      opts={vidOptions}
                      onReady={this._onReady}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      id='hero-video'
                    />
                  </div>
                )}

                {type === 'iframe' && (
                  <div className='video-container'>
                    <iframe
                      width='100%'
                      height='100%'
                      src={url}
                      id='hero-video'
                    />
                  </div>
                )}
              </div>
              <div className='form'>
                {logo && (
                  <div className='form-logo'>
                    <img src={logo} alt='' />
                  </div>
                )}
                {maintitle && (
                  <div className='form-title' style={{ color: color }}>
                    {maintitle}
                  </div>
                )}
                {mainsubtitle && (
                  <div className='form-subtitle'>{mainsubtitle}</div>
                )}
                {!isEmpty(data.re) && (
                  <div className='info'>
                    {price && (
                      <div className='info-line'>
                        <div>
                          <Money className='info-icon' />
                        </div>
                        <div>{price}</div>
                      </div>
                    )}
                    {surface && (
                      <div className='info-line'>
                        <div>
                          <Size className='info-icon' />
                        </div>
                        <div>{surface}</div>
                      </div>
                    )}
                    {relocation && (
                      <div className='info-line'>
                        <div>
                          <Location className='info-icon' />
                        </div>
                        <div>{relocation}</div>
                      </div>
                    )}
                    {delivery && (
                      <div className='info-line'>
                        <div>
                          <Key className='info-icon' />
                        </div>
                        <div>{delivery}</div>
                      </div>
                    )}
                  </div>
                )}

                <div className='form-bar' />
                <form onSubmit={this.submit}>
                  <div id='form'>
                    <div className='form-header'>
                      Remplissez les champs pour recevoir plus d'informations
                      <br />
                      املأ الإستمارة لتلقي المزيد من المعلومات
                    </div>
                    <div className='input'>
                      <input
                        onChange={this.handleInput}
                        name='name'
                        type='text'
			placeholder={
                          this.props.location.pathname === '/parrainageboutiques'
                            ? 'Votre identifiant unique | المعرف الفريد'
                            : 'Nom & Prénom | الإسم والنسب'
                        }
                        required={this.props.location.pathname !== '/parrainageboutiques'}
			pattern={
                          this.props.location.pathname === '/parrainageboutiques'
                            ? undefined
                            : '.+ .+'
                        }
                      />
                    </div>
                    <div className='input'>
                      <EMail
                        onChange={this.handleInput}
                        name='email'
                        type='email'
                        domains={customMail}
                        placeholder='Email | البريد الإلكتروني'
                        required
                      />
                    </div>

                    <div
                      className='input'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <Select
                        onChange={this.handleCountryCodeChange}
                        value={this.state.selectedCountryCode}
                        options={[
                          { label: '+212', value: '+212' },
                          { label: '+20', value: '+20' },
                          { label: '+31', value: '+31' },
                          { label: '+33', value: '+33' },
                          { label: '+34', value: '+34' },
                          { label: '+39', value: '+39' },
                          { label: '+49', value: '+49' },
                          { label: '+971', value: '+971' },
                        ]}
                        isSearchable={false}
                        name='country-code'
                        styles={{
                          container: provided => ({
                            ...provided,
                            width: '35%',
                          }),
                        }}
                      />
                      <input
                        onChange={this.handleInput}
                        name='phone'
                        type='text'
                        onKeyPress={e => this.allowNumeric(e)}
                        placeholder='Téléphone | هاتف'
                        required
                        style={{ minWidth: 0, width: '65%' }}
                      />
                    </div>

                    <div className='input'>
                      <RequiredSelect
                        onChange={this.handleCityChange}
                        options={CITIES.map(city => {
                          return { value: city, label: city }
                        })}
                        isSearchable
                        placeholder='Ville | المدينة'
                        name='city'
                      />
                    </div>

                    {iteminputName ? (
                      <div className='input'>
                        <Select
                          onChange={this.handleChange}
                          options={itemInput.map(opti => {
                            return { value: opti, label: opti }
                          })}
                          isSearchable={false}
                          placeholder={iteminputName}
                          name='drpdwn-first'
                        />
                      </div>
                    ) : null}

                    {iteminputName2 ? (
                      <div className='input'>
                        <Select
                          onChange={this.handleChange2}
                          options={itemInputSecond.map(opti2 => {
                            return { value: opti2, label: opti2 }
                          })}
                          isSearchable={false}
                          placeholder={iteminputName2}
                          name='drpdwn-second'
                        />
                      </div>
                    ) : null}

                    {textInput ? (
                      <div className='input'>
                        <input
                          onChange={this.handleInput}
                          name='customTextInput1'
                          type='text'
                          placeholder={textInput}
                          required
                        />
                      </div>
                    ) : null}

                    <div className='input'>
                      <CheckBox
                        name={'terms'}
                        required
                        onChange={this.handleCheckBoxChange}
                        defaultValue={this.state.termsCheck}
                        checked={this.state.termsCheck}
                        label={<TermsLabel />}
                      />
                    </div>

                    <div className='input'>
                      <CheckBox
                        name={'stayInformed'}
                        onChange={this.handleCheckBoxChange}
                        defaultValue={this.state.stayInformedCheck}
                        checked={this.state.stayInformedCheck}
                        label={<StayInformedLabel />}
                      />
                    </div>

                    <input
                      onChange={this.handleInput}
                      name='campaign'
                      type='hidden'
                    />
                    <input
                      onChange={this.handleInput}
                      name='medium'
                      type='hidden'
                    />
                    <div className='submit'>
                      <button
                        id='sendLead'
                        style={{ background: isColor ? color : '#0984e3' }}
                        disabled={this.state.submitting || this.state.success}
                      >
                        {button} <Arrow id='submit-arrow' />
                      </button>
                      <SweetAlert
                        show={this.state.success}
                        title='Merci!'
                        text='Vos informations ont été envoyées.'
                      />
                    </div>
                  </div>
                </form>
                <div className='form-bar' style={{ marginBottom: '5px' }} />
                {!isEmpty(data.social) && (
                  <div className='social'>
                    {web && (
                      <a href={this.formatLinkAbsolute(web)}>
                        <span className='social-icon'>
                          <Website className='info-icon' />
                        </span>
                      </a>
                    )}
                    {email && (
                      <a href={'mailto:' + email}>
                        <span className='social-icon'>
                          <Email className='info-icon' />
                        </span>
                      </a>
                    )}
                    {facebook && (
                      <a href={this.formatLinkAbsolute(facebook)}>
                        <span className='social-icon'>
                          <Facebook className='info-icon' />
                        </span>
                      </a>
                    )}
                    {youtube && (
                      <a href={this.formatLinkAbsolute(youtube)}>
                        <span className='social-icon'>
                          <Youtube className='info-icon' />
                        </span>
                      </a>
                    )}
                    {instagram && (
                      <a href={this.formatLinkAbsolute(instagram)}>
                        <span className='social-icon'>
                          <Instagram className='info-icon' />
                        </span>
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className='modules'>
              {download && (
                <div className='module mod-image mod-download'>
                  <div className='content-container'>
                    <div className='image'>
                      <img
                        src={download.image}
                        className='mod-img download-img'
                      />
                    </div>
                    <div className='text'>
                      <a href={download.file}>
                        <button
                          style={{ background: isColor ? color : '#0984e3' }}
                          className='download-btn'
                        >
                          {download.button}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {video && (
                <div className='module mod-video'>
                  <div className='title'>{video.title}</div>
                  <div className='subtitle'>{video.subtitle}</div>
                  <div className='video'>
                    <div className='video-container'>
                      <Ytube
                        videoId={video.url}
                        opts={vidOptions}
                        onReady={this._onReady}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        id='hero-video'
                      />
                    </div>
                  </div>
                </div>
              )}
              {gallery && (
                <div className='module mod-gallery'>
                  <div className='content-container'>
                    {gallery.title && (
                      <div className='title'>{gallery.title}</div>
                    )}
                    {gallery.title && (
                      <div className='subtitle'>{gallery.subtitle}</div>
                    )}
                    <div className='gallery'>
                      <Slider images={gallery.images} />
                    </div>
                  </div>
                </div>
              )}
              {imagetext && (
                <div className='module mod-image'>
                  <div className='content-container'>
                    <div className='image'>
                      <img src={imagetext.image} className='mod-img' />
                    </div>
                    <div className='text'>
                      <div className='title'>{imagetext.title}</div>
                      <div
                        style={{ whiteSpace: 'pre-line' }}
                        className='subtitle'
                      >
                        {imagetext.text}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {location && (
                <div className='module mod-map'>
                  <div className='content-container'>
                    <div className='address'>{location.address}</div>
                    <div className='map'>
                      <iframe
                        src={
                          'https://www.google.com/maps/embed/v1/place?key=AIzaSyBL2MZxHwj3d7JLHoU-UvHGcmu7-5wRf9w&q=' +
                          encodeURI(location.coord.replace(/\s/g, ''))
                        }
                        width='600'
                        height='450'
                        frameBorder='0'
                        allowFullScreen
                        className='map-iframe'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default Default
