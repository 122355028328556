import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      slidesToShow: 1,
      centerMode: true,
      dots: true,
      //   arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            arrows: false,

            dots: true,
          },
        },
      ],
    }
    let images = this.props.images
    images = Array.isArray(images) ? images : [images]
    return (
      <Slider {...settings}>
        {images.map((image, i) => (
          <div className="slide" key={i}>
            <img src={image} key={i} alt="slider" />
          </div>
        ))}
      </Slider>
    )
  }
}

export default SimpleSlider
