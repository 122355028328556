import React from 'react'
import Swiper from 'react-id-swiper'

class _Swiper extends React.Component {
  render() {
    const params = {
      autoHeight: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      grabCursor: true,
      updateOnWindowResize: false,
    }
    let images = this.props.images
    images = Array.isArray(images) ? images : [images]
    return (
      <Swiper {...params}>
        {images.map((image, i) => (
          <div className='test-slide' key={i}>
            <img src={image} alt={i} />
          </div>
        ))}
      </Swiper>
    )
  }
}

export default _Swiper
