import styled from 'styled-components'
import { size, rgba } from 'polished'

import {
  Wrapper,
  Link,
  breakpoints,
  colors,
  spacing,
  borders,
} from '@avito-ma/mosaic'

export const Container = styled(Wrapper)`
  @media (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    padding: 0 ${spacing(4)};
  }
`

export const FooterPrimary = styled.div`
  background-color: ${colors.smoke_lighter};
  padding: ${spacing(6)} 0;

  @media (max-width: ${breakpoints.md}px) {
    padding: 0;
  }
`

export const FooterSecondary = styled.div`
  background-color: ${colors.smoke_lighter};
  ${Container} {
    &:empty {
      margin: -${spacing(5)} 0;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${Container} {
      &:empty {
        display: none;
      }
    }
  }
`

export const FooterTertiary = styled.div`
  background-color: ${colors.smoke_lighter};
  padding: ${spacing(6)} 0;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0 0 ${spacing(7)};
  }

  ${Container} {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: column;
    }
  }
`

export const FooterTertiaryText = styled.p`
  color: ${colors.midnight_normal};
`

const LinkTransition = `
  transition-property: transform, box-shadow;
  transition-duration: .25s;
  &:hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 ${spacing(2)} ${spacing(4)} ${rgba(colors.black, 0.15)};
  }
`

export const PagesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const PageItem = styled.li`
  &:not(:last-child) {
    margin-right: ${spacing(5)};
  }
`

export const PageLink = styled(Link)`
  color: ${colors.midnight_light};
  transition: 0.25s color;
  @media (max-width: ${breakpoints.sm}px) {
    line-height: 2.2;
  }

  &:hover {
    color: ${colors.secondary_normal};
    text-decoration: none;
  }
`

export const Stores = styled.div`
  @media (max-width: 560px) {
    margin-right: ${spacing(7)};
  }

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: ${spacing(7)};
  }
`

export const StoresList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const StoreItem = styled.li`
  &:not(:last-child) {
    margin-right: ${spacing(4)};
    @media (max-width: ${breakpoints.sm}px) {
      margin-bottom: ${spacing(3)};
      margin-right: ${spacing(3)};
    }
    @media (max-width: ${breakpoints.md}px) {
      margin-right: ${spacing(3)};
    }
  }
`

export const StoreLink = styled(Link)`
  display: block;
  height: 48px;
  ${LinkTransition};

  @media (max-width: ${breakpoints.md}px) {
    height: ${spacing(7)};
  }
`

export const StoreBadge = styled.img`
  display: block;
  height: 100%;
  width: auto;
`

export const SocialMedia = styled.div`
  @media (max-width: ${breakpoints.md}px) {
    text-align: center;
  }

  @media (max-width: ${breakpoints.sm}px) {
    text-align: unset;
  }
`

export const SocialMediaList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const SocialMediaItem = styled(StoreItem)``

export const SocialMediaLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${size('38px')};
  border-radius: ${borders.radiusMax};
  ${({ color }) => `background-color: ${color};`}
  ${LinkTransition};
`

export const Footer = styled.footer`
  background-color: ${colors.smoke_lighter};
  color: ${colors.midnight_light};
  border-top: 1px solid ${colors.smoke_lighter};

  @media (max-width: ${breakpoints.md}px) {
    padding: 33px 0 88px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    display: flex;
    flex-direction: column;

    ${FooterSecondary} {
      order: 1;
    }
    ${FooterTertiary} {
      order: 2;
    }
    ${FooterPrimary} {
      order: 3;
    }
  }
`
