export const CGU_URL = 'https://aide.avito.ma/cgu/'
export const cookieBaseDomain = '.avitomedia.com'
export const CITIES = [
  'Agadir',
  'Aït Melloul',
  'Azrou',
  'Beni mellal',
  'Berrechid',
  'Berkane',
  'Casablanca',
  'Dakhla',
  'El jadida',
  'Errachidia',
  'Fès',
  'Fkih ben Saleh',
  'Guelmim',
  'Inzegane',
  'Kenitra',
  'Khouribga',
  'Laayoune',
  'Mohammedia',
  'Marrakech',
  'Meknes',
  'Nador',
  'Oujda',
  'Ouarzazate',
  'Rabat',
  'Salé',
  'Safi',
  'Temara',
  'Tiznit',
  'Tétouan',
  'Taza',
  'Tanger',
]
