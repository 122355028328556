import React from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap'
import { Camera, Accountcircle } from '@avito-ma/sui-svgiconset'
import PropTypes from 'prop-types'
import '../common/Layout/header.scss'

import Logo from '../../assets/icons/logo.svg'

const baseUrl = 'https://www.avito.ma'

export const HeaderUi = props => (
  <div className="av-navBar-wrapper shadow-sm sticky-top bg-white row mx-0">
    <Navbar
      className="av-navBar container-fluid col-md-10 px-lg-0"
      color="white"
      light
      expand
    >
      <NavbarBrand className="" href="https://www.avito.ma/">
        <Logo />
      </NavbarBrand>
      <div className="height-auto ml-auto" isOpen={props.isOpen} navbar>
        <Nav className="align-items-center" navbar>
          <NavItem className="d-none d-sm-none d-md-block">
            <NavLink
              className="av-headerLink av-link p-2 mx-2"
              href={`${baseUrl}/fr/maroc/%C3%A0_vendre`}
            >
              Rechercher
            </NavLink>
          </NavItem>
          <NavItem className="mt-2 d-block d-none d-sm-block d-md-none">
            <NavLink
              className="av-headerLink av-link p-2 mx-2"
              href={`${baseUrl}/fr/maroc/%C3%A0_vendre`}
            >
              <div className="av-SharedHeaderSearchMenuIcon">
                {/* <SearchIcon /> */}
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="av-headerShops d-none d-sm-none d-md-block">
            <NavLink
              className="av-headerLink av-link p-2 mx-2"
              target="_blank"
              href="https://www.avito.ma/fr/boutiques/maroc/"
            >
              <span className="av-headerShop-badge">Pro</span>
              Boutiques
            </NavLink>
          </NavItem>

          <UncontrolledDropdown
            onClick={() => {
              window.open('https://account.avito.ma/', '_blank')
            }}
            className="av-headerAccount mr-sm-2 ml-sm-3 text-nowrap"
          >
            <DropdownToggle className="d-flex align-items-center px-0" nav>
              <Accountcircle size={24} fillColor="#666" />
              <span className="av-headerLink mx-2 d-none d-sm-none d-md-none d-lg-inline-block text-truncate">
                Mon compte
              </span>
            </DropdownToggle>
          </UncontrolledDropdown>
          <NavItem className="py-2 mx-lg-2 mx-md-2">
            <a
              className="btn btn-secondary av-headerAI d-none d-md-flex justify-content-center"
              href="https://www.avito.ma/fr/ai"
              target="_blank"
              rel="noreferrer"
            >
              <Camera
                size={24}
                fillColor="white"
                svgClass="av-headerAISVG mr-2 av-sideMenu-AISVG mr-1"
              />
              Déposer une annonce
            </a>
          </NavItem>
        </Nav>
      </div>
    </Navbar>
  </div>
)

HeaderUi.propTypes = {
  user: PropTypes.object,
  baseUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  adInsertClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  modal: PropTypes.bool,
  modalAi: PropTypes.element,
  notificationsCheckSm: PropTypes.element,
  notificationsCheckUnread: PropTypes.element,
  notificationsCheckDesktop: PropTypes.element,
  boutiqueLink: PropTypes.string, // Tobe add in avito-react-js
  onStateChange: PropTypes.func,
}
